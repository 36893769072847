import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import router from '@/bootstrap/router/router'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import EventService from '@/modules/common/services/event/event.service'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrTrackOrderView',
  computed: {
    ...mapState('option', ['option_groups']),
    ...mapState('event', ['event'])
  }
})

export default class GtrTrackOrderView extends GtrSuper {
  option_groups!: Record<string, any>

  event!: Record<string, any>

  data () {
    return {
      loading: false
    }
  }

  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response.data) {
      const registrationModuleActive = response.data.SESSION_TRACKING.enabled
      if (registrationModuleActive) {
        next()
      } else {
        const message = 'Track Module not activated. Please, activate it and try again...'
        router.push({ name: 'level-two.event.modules', params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid } }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }

  async mounted () {
    try {
      this.$data.loading = true
      this.$store.dispatch('common/showLoader', { value: true })
      await this.fetchEvent()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
      this.$data.loading = false
    }
  }

  async fetchEvent () {
    try {
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }
}
